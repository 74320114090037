import React from 'react';
import { Heart } from 'lucide-react';

const Navigation = ({ onTrialClick, onLoginClick }) => {
  return (
    <nav className="container mx-auto px-4 py-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Heart className="text-purple-600 w-6 h-6" />
          <span className="font-bold text-xl">DailySpirit</span>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={onLoginClick}
            className="bg-purple-600 text-white px-4 py-2 rounded-full text-sm font-medium hover:bg-purple-700 transition-colors"
          >
            Login
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;