import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Heart, Mail, Star, Coffee, CheckCircle, ArrowRight, Users, XCircle, Apple } from 'lucide-react';
import FreeTrialForm from './components/FreeTrialForm/FreeTrialForm';
import SubscribeForm from './components/SubscribeForm/SubscribeForm';
import LoginForm from './components/LoginForm/LoginForm';
import Navigation from './components/Navigation/Navigation';


export default function LandingPage() {
  const [isTrialModalOpen, setTrialModalOpen] = useState(false);
  const [isSubscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const openTrialModal = () => setTrialModalOpen(true);
  const openSubscribeModal = () => setSubscribeModalOpen(true);
  const openLoginModal = () => setLoginModalOpen(true);
  const handleError = (message) => {
    console.log('Landing Page Error Handler:', message); // Debug için
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 5000);
  };

  // Error Notification component
  const ErrorNotification = () => {
    if (!errorMessage) return null;

    return (
      <div className="fixed inset-x-0 top-8 flex justify-center items-start z-50">
        <div
          className="bg-red-600 text-white px-6 py-4 rounded-lg shadow-2xl flex items-center max-w-md mx-4 animate-bounce-in">
          <div className="bg-red-500 p-2 rounded-full mr-3">
            <XCircle className="w-6 h-6" />
          </div>
          <span className="flex-1 font-medium">{errorMessage}</span>
          <button
            onClick={() => setErrorMessage('')}
            className="ml-4 text-white/80 hover:text-white p-1 rounded-full hover:bg-red-500/50 transition-colors"
          >
            <XCircle className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      {/* Navigation */}
      <Navigation
        onTrialClick={openTrialModal}
        onLoginClick={openLoginModal}
      />
      {/* Error Notification */}
      <ErrorNotification />

      {/* Hero Section */}
      <header className="container mx-auto px-4 py-16 text-center">
        <span className="inline-block bg-purple-100 text-purple-700 px-4 py-1 rounded-full text-sm font-medium">
          Join 10,000+ subscribers
        </span>
        <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mt-6 mb-6">
          Daily Dose of <span className="text-purple-600">Inspiration</span>
        </h1>
        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
          Start your day with carefully curated motivational messages, inspiring stories,
          and actionable insights delivered to your inbox.
        </p>
        <button
          className="bg-purple-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-purple-700 transition-colors"
        >
          Start for Just $0.99/Month
        </button>
        <p className="text-sm text-gray-500 mt-4">Cancel anytime</p>
        <div className="flex justify-center items-center mt-4 bg-purple-100 p-3 rounded-full shadow-lg">
          <Apple className="w-6 h-6 text-purple-600 mr-2" />
          <span className="text-sm text-purple-700 font-semibold">Soon on App Store</span>
        </div>
      </header>

      {/* Social Proof */}
      <section className="container mx-auto px-4 py-8">
        <div className="flex items-center justify-center gap-2 text-gray-400">
          <Users className="w-5 h-5" />
          <p className="text-sm">Trusted by members from USA • UK • Canada • Australia • Europe • Asia</p>
        </div>
      </section>

      {/* Features */}
      <section className="container mx-auto px-4 py-16">
        <div className="grid md:grid-cols-3 gap-8">
          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
            <Mail className="text-purple-600 w-8 h-8 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Personalized Messages</h3>
            <p className="text-gray-600">Daily curated content tailored to your personal growth journey.</p>
          </div>
          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
            <Star className="text-purple-600 w-8 h-8 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Inspiring Content</h3>
            <p className="text-gray-600">Success stories, wisdom teachings, and positive thinking
              techniques.</p>
          </div>
          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
            <Coffee className="text-purple-600 w-8 h-8 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Daily Micro-Tasks</h3>
            <p className="text-gray-600">Small but impactful daily activities for personal development.</p>
          </div>
        </div>
      </section>

      {/* Preview */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-12">Preview Your Daily Message</h2>
        <div className="bg-white rounded-xl shadow-sm p-8 max-w-lg mx-auto">
          <div className="space-y-6">
            <div>
              <span className="text-purple-600 font-medium">Today's Quote</span>
              <p className="text-xl font-medium mt-2">
                "Success is not final, failure is not fatal: it is the courage to continue that counts."
              </p>
              <p className="text-gray-500 mt-1">- Winston Churchill</p>
            </div>
            <div>
              <span className="text-purple-600 font-medium">Daily Focus</span>
              <p className="text-gray-700 mt-2">Resilience and Persistence</p>
            </div>
            <div>
              <span className="text-purple-600 font-medium">Micro-Task</span>
              <p className="text-gray-700 mt-2">
                Write down one challenge you're facing and three potential ways to overcome it.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits */}
      <section className="container mx-auto px-4 py-16 bg-gray-50">
        <h2 className="text-3xl font-bold text-center mb-12">Why Choose DailySpirit?</h2>
        <div className="max-w-2xl mx-auto space-y-8">
          <div className="flex gap-4">
            <CheckCircle className="text-purple-600 w-6 h-6 flex-shrink-0" />
            <div>
              <h3 className="font-semibold mb-1">Start Every Day Positively</h3>
              <p className="text-gray-600">Begin your mornings with uplifting messages and motivation.</p>
            </div>
          </div>
          <div className="flex gap-4">
            <CheckCircle className="text-purple-600 w-6 h-6 flex-shrink-0" />
            <div>
              <h3 className="font-semibold mb-1">Science-Backed Approach</h3>
              <p className="text-gray-600">Content based on positive psychology and personal growth
                research.</p>
            </div>
          </div>
          <div className="flex gap-4">
            <CheckCircle className="text-purple-600 w-6 h-6 flex-shrink-0" />
            <div>
              <h3 className="font-semibold mb-1">Personalized Experience</h3>
              <p className="text-gray-600">Content customized to your interests and goals.</p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="container mx-auto px-4 py-16">
        <div className="bg-purple-600 rounded-2xl p-8 md:p-12 text-center max-w-3xl mx-auto text-white">
          <h2 className="text-3xl font-bold mb-6">Join Our Global Community</h2>
          <p className="text-lg opacity-90 mb-8">
            Start your journey to daily inspiration and personal growth.
          </p>
        </div>
      </section>

      {/* Footer */}
      <footer className="container mx-auto px-4 py-8 border-t">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex items-center space-x-2">
            <Heart className="text-purple-600 w-5 h-5" />
            <span className="font-medium">DailySpirit</span>
          </div>
          <div className="flex gap-6 text-sm text-gray-600">
            <Link to="/privacy" className="hover:text-purple-600">Privacy</Link>
            <Link to="/terms" className="hover:text-purple-600">Terms</Link>
            <Link to="/contact" className="hover:text-purple-600">Contact</Link>
          </div>
          <p className="text-sm text-gray-500">© 2024 DailySpirit. All rights reserved.</p>
        </div>
      </footer>

      {/* Modals */}
      <FreeTrialForm
        isOpen={isTrialModalOpen}
        onClose={() => setTrialModalOpen(false)}
        onError={handleError}
      />

      <SubscribeForm
        isOpen={isSubscribeModalOpen}
        onClose={() => setSubscribeModalOpen(false)}
        onError={handleError}
      />

      <LoginForm
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        onError={handleError}
      />
    </div>
  );
}