import React, { useState } from 'react';
import { Mail, Loader, ArrowRight } from 'lucide-react';
import Modal from '../../../../components/common/Modal/Modal';
import { authService } from '../../../../services/api';

const ForgotPasswordForm = ({ isOpen, onClose, onBackToLogin }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const result = await authService.forgotPassword(email);

        if (result.success) {
            setSuccess(true);
            // Production'da email gönderildiğinde gösterilecek
            setTimeout(() => {
                onClose();
                if (onBackToLogin) onBackToLogin();
            }, 3000);
        } else {
            setError(result.error);
        }

        setLoading(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="p-8">
                {success ? (
                    <div className="text-center py-8">
                        <div className="bg-green-100 rounded-full p-3 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                            <Mail className="w-8 h-8 text-green-600" />
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Check Your Email</h3>
                        <p className="text-gray-600">
                            If an account exists for {email}, you will receive password reset instructions.
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="text-center mb-6">
                            <h2 className="text-2xl font-bold mb-2">Reset Password</h2>
                            <p className="text-gray-600">
                                Enter your email address and we'll send you instructions to reset your password.
                            </p>
                        </div>

                        {error && (
                            <div className="bg-red-50 text-red-600 p-3 rounded-lg mb-4 text-sm">
                                {error}
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Email Address
                                </label>
                                <div className="relative">
                                    <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="pl-10 w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                        placeholder="john@example.com"
                                        required
                                        disabled={loading}
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full bg-purple-600 text-white p-3 rounded-lg font-medium hover:bg-purple-700 transition-colors flex items-center justify-center gap-2 disabled:opacity-50"
                            >
                                {loading ? (
                                    <>
                                        <Loader className="w-5 h-5 animate-spin" />
                                        Processing...
                                    </>
                                ) : (
                                    <>
                                        Send Reset Link <ArrowRight size={20} />
                                    </>
                                )}
                            </button>

                            <button
                                type="button"
                                onClick={onBackToLogin}
                                className="w-full text-gray-600 hover:text-purple-600 text-sm mt-4"
                            >
                                Back to Login
                            </button>
                        </form>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ForgotPasswordForm;