import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.href = '/';
            }
            return Promise.reject(error.response.data);
        }
        return Promise.reject({detail: 'Network error'});
    }
);

export const authService = {
    async register(userData) {
        try {
            const response = await api.post('/auth/register', userData);
            if (response.data.access_token) {
                localStorage.setItem('token', response.data.access_token);
            }
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            return {
                success: false,
                error: error.detail || 'Registration failed'
            };
        }
    },

    async login(credentials) {
        try {
            const response = await api.post('/auth/login', credentials);
            if (response.data.access_token) {
                localStorage.setItem('token', response.data.access_token);
            }
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            return {
                success: false,
                error: error.detail || 'Login failed'
            };
        }
    },

    async forgotPassword(email) {
        try {
            const response = await api.post('/auth/forgot-password', {email});
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            return {
                success: false,
                error: error.response?.data?.detail || 'Failed to process request'
            };
        }
    }
};

export const subscriptionService = {
    async subscribe(email) {
        try {
            const response = await api.post('/subscriptions/newsletter', {
                email: email
            });
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            console.log('Subscription Error:', error); // Debug için

            // Email already exists hatası
            if (error.detail === "Email already subscribed") {
                return {
                    success: false,
                    error: "Email already subscribed"
                };
            }

            // Validasyon hatası
            if (error.status === 422) {
                return {
                    success: false,
                    error: "Please enter a valid email address"
                };
            }

            // Diğer hatalar
            return {
                success: false,
                error: 'An unexpected error occurred'
            };
        }
    }
};

export const contactService = {
    async sendMessage(messageData) {
        try {
            const response = await api.post('/contact', messageData);
            return {
                success: true,
                data: response.data
            };
        } catch (error) {
            return {
                success: false,
                error: error.response?.data?.detail || 'Failed to send message'
            };
        }
    }
};