import React, { useState } from 'react';
import { Mail, Loader, ArrowRight, CheckCircle } from 'lucide-react';
import Modal from '../../../../components/common/Modal/Modal';
import { subscriptionService } from '../../../../services/api';

const SubscribeForm = ({ isOpen, onClose, onError }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const result = await subscriptionService.subscribe(email);
            console.log('Subscribe Result:', result); // Debug için

            if (result.success) {
                setSuccess(true);
                setTimeout(() => {
                    setEmail('');
                    setSuccess(false);
                    onClose();
                }, 2000);
            } else {
                // onError fonksiyonunun varlığını kontrol et
                if (typeof onError === 'function') {
                    onError(result.error);
                } else {
                    console.error('Error handler not provided:', result.error);
                }
            }
        } catch (error) {
            console.error('Submission error:', error);
            if (typeof onError === 'function') {
                onError('An unexpected error occurred');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setEmail('');
        setSuccess(false);
        setLoading(false);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <div className="p-8">
                {success ? (
                    <div className="text-center py-8">
                        <div className="flex justify-center mb-4">
                            <div className="bg-green-100 p-3 rounded-full">
                                <CheckCircle className="text-green-600 w-8 h-8" />
                            </div>
                        </div>
                        <h3 className="text-xl font-semibold mb-2">Successfully Subscribed!</h3>
                        <p className="text-gray-600">Thank you for subscribing to our newsletter.</p>
                    </div>
                ) : (
                    <>
                        <div className="text-center mb-6">
                            <div className="flex justify-center mb-4">
                                <Mail className="text-purple-600 w-10 h-10" />
                            </div>
                            <h2 className="text-2xl font-bold mb-2">Subscribe to Our Newsletter</h2>
                            <p className="text-gray-600">Get daily inspiration and updates</p>
                        </div>

                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Email Address
                                </label>
                                <div className="relative">
                                    <Mail
                                        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5"
                                    />
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="pl-10 w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                        placeholder="john@example.com"
                                        required
                                        disabled={loading}
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full bg-purple-600 text-white p-3 rounded-lg font-medium hover:bg-purple-700 transition-colors flex items-center justify-center gap-2 disabled:opacity-50"
                            >
                                {loading ? (
                                    <>
                                        <Loader className="w-5 h-5 animate-spin" />
                                        Processing...
                                    </>
                                ) : (
                                    <>
                                        Subscribe Now <ArrowRight size={20} />
                                    </>
                                )}
                            </button>
                        </form>

                        <p className="text-sm text-gray-500 text-center mt-6">
                            No spam, unsubscribe at any time
                        </p>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default SubscribeForm;