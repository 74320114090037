import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const TermsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <Link to="/" className="inline-flex items-center text-gray-600 hover:text-purple-600 mb-8">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </Link>

        <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-sm p-8">
          <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

          <div className="space-y-6 text-gray-600">
            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">1. Terms</h2>
              <p>By accessing our service, you agree to be bound by these terms of service and comply with all applicable laws and regulations.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-800 mb-3">2. Use License</h2>
              <p>Permission is granted to temporarily access the materials (information or software) on DailySpirit's website for personal, non-commercial use only.</p>
            </section>



            {/* Add more sections as needed */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;