import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const PrivacyPage = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <div className="container mx-auto px-4 py-8">
                <Link to="/" className="inline-flex items-center text-gray-600 hover:text-purple-600 mb-8">
                    <ArrowLeft className="w-5 h-5 mr-2" />
                    Back to Home
                </Link>

                <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-sm p-8">
                    <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

                    <div className="space-y-6 text-gray-600">
                        <section>
                            <h2 className="text-xl font-semibold text-gray-800 mb-3">Information We Collect</h2>
                            <p>We collect information you provide directly to us, including:</p>
                            <ul className="list-disc ml-6 mt-2 space-y-2">
                                <li>Name and email address when you sign up</li>
                                <li>Usage information when you interact with our services</li>
                                <li>Payment information when you subscribe</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className="text-xl font-semibold text-gray-800 mb-3">How We Use Your Information</h2>
                            <p>We use the information we collect to:</p>
                            <ul className="list-disc ml-6 mt-2 space-y-2">
                                <li>Provide and maintain our services</li>
                                <li>Send you daily inspiration and newsletters</li>
                                <li>Improve and personalize your experience</li>
                            </ul>
                        </section>

                        {/* Add more sections as needed */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPage;